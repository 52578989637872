import { useFlag } from '@unleash/proxy-client-react';
import type { ReactNode } from 'react';
import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useTheme } from '../../hooks/useTheme';

export function RootTheme({ children }: { children: ReactNode }) {
  const [theme] = useTheme();
  const isThemePreferenceEnabled = useFlag('theme-preference');

  useLayoutEffect(() => {
    document.body.classList.toggle('ppl-theme-light', !isThemePreferenceEnabled || theme !== 'dark');
    document.body.classList.toggle('ppl-theme-dark', isThemePreferenceEnabled && theme === 'dark');
  }, [isThemePreferenceEnabled, theme]);

  return (
    <>
      {isThemePreferenceEnabled ? (
        <Helmet>
          <meta name="color-scheme" content={theme} />
        </Helmet>
      ) : null}

      {children}
    </>
  );
}
